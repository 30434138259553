import {Fragment, useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    IconButton, InputLabel, MenuItem, Select, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Delete, Download, Edit} from "@mui/icons-material";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {specialConsiderationsQueryKey} from "../../constants";
import {
    createSpecialConsideration,
    deleteSpecialConsideration,
    getAllSpecialConsiderations, updateSpecialConsideration
} from "../../api/specialConsiderations";
import {getAllClaims} from "../../api/claims";
import {getAllRegions} from "../../api/regions";
import {getAllIndustries} from "../../api/industries";
import LoginContext from "../../login-context/LoginContext";
import ChipMultiSelect from "../custom/ChipMultiSelect";

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});


const SpecialConsiderations = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editNewDialogOpen, setEditNewDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({name: "", id: ""});
    const [itemToEdit, setItemToEdit] = useState({name: "", id: ""});
    const [isNewItem, setIsNewItem] = useState(false);
    const [claims, setClaims] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [tokenResponse, setTokenResponse] = useContext(LoginContext)
    const queryClient = useQueryClient();

    useEffect(() => {
        getAllClaims(tokenResponse?.access_token, () => setTokenResponse({}))().then(data => setClaims(data));
        getAllRegions().then(data => setRegions(data.map(i => {
            return {id: i.id, name: i.description}
        })));
        getAllIndustries().then(data => setIndustries(data));
    }, [setTokenResponse, tokenResponse?.access_token]);

    const handleExport = (data) => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const openDeleteConfirmModal = (item) => {
        setItemToDelete(item.original);
        setDeleteDialogOpen(true);
    }

    const onDeleteSpecialConsideration = (id) => {
        mutateDeleteSpecialConsideration(id);
        setDeleteDialogOpen(false);
    }

    const onEditSpecialConsiderationDialogOpen = (item) => {
        setIsNewItem(false);
        setItemToEdit(item.original);
        setEditNewDialogOpen(true);
    }

    const onSubmitButtonClick = async () => {
        if(isNewItem) {
            await mutateCreateSpecialConsideration(itemToEdit);
        } else {
            await mutateUpdateSpecialConsideration(itemToEdit);
        }
        setEditNewDialogOpen(false)
    }

    const onCreateCertificationDialogOpen = () => {
        setIsNewItem(true);
        setItemToEdit({});
        setEditNewDialogOpen(true);
    }

    const {
        isLoading: specialConsiderationsLoading,
        data: specialConsiderations = []
    } = useQuery(specialConsiderationsQueryKey, getAllSpecialConsiderations, {refetchOnWindowFocus: false});

    const {
        mutate: mutateDeleteSpecialConsideration,
        isLoading: deleteSpecialConsiderationLoading
    } = useMutation({
        mutationFn: deleteSpecialConsideration,
        onSettled: () => queryClient.invalidateQueries(specialConsiderationsQueryKey)
    });


    const {
        mutate: mutateUpdateSpecialConsideration,
        isLoading: updateSpecialConsiderationLoading
    } = useMutation({
        mutationFn: updateSpecialConsideration,
        onSettled: () => queryClient.invalidateQueries(specialConsiderationsQueryKey)
    });

    const {
        mutate: mutateCreateSpecialConsideration,
        isLoading: createSpecialConsiderationLoading
    } = useMutation({
        mutationFn: createSpecialConsideration,
        onSettled: () => queryClient.invalidateQueries(specialConsiderationsQueryKey)
    });

    const columns = [{
            accessorKey: 'id',
            header: 'ID',
            type: 'number',
            enableEditing: false,
            size: 50
        },
        {
            accessorKey: 'consideration',
            header: 'Special Consideration',
            type: 'string',
            enableEditing: true,
            size: 350
        },
        {
            accessorFn: original => claims.find(c => c.id === original.claimId)?.name,
            header: 'Claim'
        },
        {
            accessorFn: original => original.industryIds?.map(i => industries.find(ind => ind.id === i)?.name).join(", "),
            header: 'Product Category',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.industryIds?.map((industry, index) => (
                            <Chip key={index} label={(industries || []).find(i => i.id === industry)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },
        {
            accessorFn: original => original.regionIds?.map(r => regions.find(reg => reg.id === r)?.name).join(", "),
            header: 'Regions',
            type: 'string',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.regionIds?.map((region, index) => (
                            <Chip key={index} label={(regions || []).find(i => i.id === region)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },

    ];

    return (
        <Fragment>
            <Typography variant="h4" sx={{mb: 5}}>Special Considerations</Typography>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete item "{itemToDelete.consideration}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={() => onDeleteSpecialConsideration(itemToDelete.id)}>Yes</Button>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editNewDialogOpen} fullWidth maxWidth={"md"}>
                <DialogTitle>
                    {isNewItem ? "Add New Special Consideration" : "Edit Special Consideration"}
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"}>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="green-cmlaim-select-label">Green Claim</InputLabel>
                            <Select
                                labelId="green-cmlaim-select-label"
                                id="demo-simple-select"
                                value={itemToEdit.claimId}
                                label="Green Claim"
                                onChange={(e) => setItemToEdit({...itemToEdit, claimId: e.target.value})}
                            >
                                {claims.map(claim => (
                                    <MenuItem value={claim.id}>{claim.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <ChipMultiSelect options={industries} selectedOptions={itemToEdit.industryIds || []}
                                         label={"Product Categories"} sx={{mt: 2, flexDirection: "row"}} selectAllEnabled
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, industryIds: v})}/>
                        <ChipMultiSelect options={regions}
                                         selectedOptions={itemToEdit.regionIds || []}
                                         label={"Regions"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, regionIds: v})}/>
                        <TextField label={"Consideration"} sx={{mt: 2}} value={itemToEdit.consideration} multiline rows={5}
                                   onChange={e => setItemToEdit({...itemToEdit, consideration: e.target.value})}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setEditNewDialogOpen(false)}>Cancel</Button>
                    <Button variant="contained" type={"submit"} onClick={onSubmitButtonClick}>Save</Button>
                </DialogActions>
            </Dialog>
            <MaterialReactTable
                columns={columns}
                data={specialConsiderations}
                enableEditing={true}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Button
                                variant="contained"
                                onClick={onCreateCertificationDialogOpen}
                            >
                                Add Special Consideration
                            </Button>
                            <Button variant={"contained"} sx={{ml: 2}}
                                    onClick={_ => handleExport(specialConsiderations)}>
                                <Download/>
                                Download CSV
                            </Button>
                        </Box>
                    );

                }}
                state={{
                    isLoading: specialConsiderationsLoading,
                    isSaving: deleteSpecialConsiderationLoading || updateSpecialConsiderationLoading || createSpecialConsiderationLoading,
                }}
                enablePagination={true}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilterModes={true}
                enableColumnPinning={true}
                enableColumnResizing={true}
                initialState={{
                    showColumnFilters: false,
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 180, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
                        grow: false,
                    },
                }}
                renderRowActions={({row, table}) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => onEditSpecialConsiderationDialogOpen(row)}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }}


            />
        </Fragment>
    )
}

export default SpecialConsiderations;